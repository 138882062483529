import "./styles.css";
import PartySocket from "partysocket";
declare const PARTYKIT_HOST: string;
// import { tokenizeAndEstimateCost } from "llm-cost";

class Client {
  private output: HTMLDivElement;
  private conn: PartySocket;
  private pingInterval: ReturnType<typeof setInterval> | null = null;

  constructor() {
    this.output = document.getElementById("app") as HTMLDivElement;
    this.conn = this.initializeConnection();
    this.attachEventListeners();
    this.restoreClaudeKey();
  }

  private initializeConnection() {
    return new PartySocket({
      host: PARTYKIT_HOST,
      room: "my-new-room",
    });
  }

  private add(text: string) {
    this.output.appendChild(document.createTextNode(text));
    this.output.appendChild(document.createElement("br"));
  }

  private attachEventListeners() {
    this.conn.addEventListener("message", (event) => this.handleMessage(event));
    this.conn.addEventListener("open", () => this.handleOpen());
    // document.getElementById("money")!.addEventListener("input", (event) => this.handleMoneyInput(event));
    document.getElementById("generate")!.addEventListener("click", () => this.requestProgram());
    document.getElementById("claude-api-key")!.addEventListener("keydown", (event) => this.handleClaudeApiKeyInput(event));
    // document.getElementById("cost-estimator")!.addEventListener("keydown", async (event) => await this.handleCostEstimatorInput(event));
  }


// async function main() {
//   const result = await tokenizeAndEstimateCost({
//     model: "gpt-4",
//     input: "Hello, world!",
//     output: "Hi there, how are you?",
//   });

//   console.log(result);
//   // Output: { inputTokens: 4, outputTokens: 7, cost: 0.00054 }
// }

  // private async handleCostEstimatorInput(event: Event) {
  //   const result = await tokenizeAndEstimateCost({
  //     model: "gpt-4",
  //     input: "Hello, world!",
  //     output: "Hi there, how are you?",
  //   });
  //   console.log(result);
  // }



  private handleClaudeApiKeyInput(event: Event) {
    this.storeClaudeKey();
  }

  private storeClaudeKey() {
    const claudeApiKeyElt = document.getElementById("claude-api-key") as HTMLInputElement;
    const claudeApiKey = claudeApiKeyElt.value;
    if (claudeApiKey) {
      localStorage.setItem('claudeApiKey', claudeApiKey);
    }
  }
  private restoreClaudeKey() {
    const claudeApiKeyElt = document.getElementById("claude-api-key") as HTMLInputElement;
    const storedKey = localStorage.getItem('claudeApiKey');
    if (storedKey) {
      claudeApiKeyElt.value = storedKey;
    }
  }

  private handleMessage(event: MessageEvent) {
    const parsedEvent = JSON.parse(event.data);
    if (parsedEvent.message) {
      this.add(parsedEvent.message);
      return;
    }
    if (parsedEvent.error) {
      this.add(parsedEvent.error);
      return;
    }
    const aiCode = parsedEvent.aiResponse;
    const code = aiCode.match(/```(.*?)```/s);
    let p5Code = "";
    if (code) {
      p5Code = code[1]
      .replace(/^js/, '')
      .replace(/^javascript/, '')
    }
    console.log(p5Code);

    var script = document.createElement("script");
    script.innerHTML = p5Code;
    document.head.appendChild(script);
    setTimeout(() => {
      // Move the last canvas to the top of the div "#gallery"
      // const canvas = document.querySelector("canvas");
      // if (canvas) {
      //   const gallery = document.getElementById("gallery")!;
      //   if (gallery.firstChild) {
      //     gallery.insertBefore(canvas, gallery.firstChild);
      //   } else {
      //     gallery.appendChild(canvas);
      //   }
      // }
    }, 1000);
  }

  private handleOpen() {
    this.add("Connected!");
    if (this.pingInterval) {
      clearInterval(this.pingInterval);
    }
  }

  private requestProgram() {
    const claudeApiKey = this.getClaudeKey();
    if (claudeApiKey) {
      this.conn.send(JSON.stringify({
        key: claudeApiKey,
      }));
    } else {
      document.getElementById("claude-api-key-error")!.innerText = "Please enter an API key to generate.";
      document.getElementById("claude-api-key")!.focus();
    }
  }

  private getClaudeKey() {
    const claudeApiKeyElt = document.getElementById("claude-api-key") as HTMLInputElement
    return claudeApiKeyElt.value;
  }

  // private handleMoneyInput(event: Event) {
  //   const value = (event.target as HTMLInputElement).value;
  //   document.getElementById("money-value")!.innerText = `\$${Number(value)/100} of code`;
  // }
}

new Client();
